import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation} from '@angular/router'
import {environment} from '../environments/environment'
import routes from './app.routes'
import {ENVIRONMENT} from './application/app'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const configService = inject(ConfigService)
      return configService.bootstrap()
    }),
    // Used by config service and has to be provided in "root"
    {provide: ENVIRONMENT, useValue: environment},
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimationsAsync()
  ]
}